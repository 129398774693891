import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Col, Row } from 'react-flexbox-grid';
import { Card, CardContent, Typography } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { withRouter } from 'react-router';
import Link from 'ui/base/Link';
import MentiaLogo from 'images/MentiaLogo2024.jpg';
import MentiaCompanionLogo from 'images/MentiaCompanion2024.png';
import DevaWorldLogo from 'images/logo_deva_world.png';
import CarePlayLogo from 'images/CarePlayLogo2024.png';
import {LocaleConsumer} from 'containers/locale/LocaleProvider';

const styles = {
    row: {
        height: '100%',
        textAlign: 'left',
    },
};

class JoinSuccessPage extends React.Component {
    state = { };

    constructor(props) {
        super(props);
    }

    render() {
        const { classes } = this.props;

        // let CAREPLAY_CLIENT_URL;
        // let DEVA_CLIENT_URL;

        // const baseUrl = new URL(window.location.href).origin;
        
        // if (baseUrl.includes('client')) {
        //     CAREPLAY_CLIENT_URL = process.env.CAREPLAY_DEV_CLIENT_URL;
        //     DEVA_CLIENT_URL = process.env.DEVA_DEV_CLIENT_URL;
        // } else if (baseUrl.includes('au')) {
        //     CAREPLAY_CLIENT_URL = process.env.CAREPLAY_AU_CLIENT_URL;
        //     DEVA_CLIENT_URL = process.env.DEVA_AU_CLIENT_URL;
        // } else if (baseUrl.includes('fr')) {
        //     CAREPLAY_CLIENT_URL = process.env.CAREPLAY_FR_CLIENT_URL;
        //     DEVA_CLIENT_URL = process.env.DEVA_FR_CLIENT_URL;
        // } else {
        //     CAREPLAY_CLIENT_URL = process.env.CAREPLAY_CA_CLIENT_URL;
        //     DEVA_CLIENT_URL = process.env.DEVA_CA_CLIENT_URL;
        // }

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <Row className={classes.row} center="xs" middle="xs">
                    <Col xs={12} sm={8} lg={6}>
                        <div className={classes.cardContainer}>
                            <Card classes={{ root: classes.cardRoot }}>
                                <CardContent>
                                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', padding: '3vh' }}>
                                        <Typography align="center" style={{ marginTop: '1rem' }}>
                                            <img src={MentiaLogo} height={50} alt="" />
                                        </Typography>
                                        <br />
                                        <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}> 
                                            <img src={DevaWorldLogo} height={25} alt="" />
                                            <img src={MentiaCompanionLogo} height={30} alt="" />
                                            <img src={CarePlayLogo} height={32} alt="" />
                                        </div>
                                        <br />
                                        <Typography style={{ marginTop: '2vh', marginBottom: '2vh', fontSize: '1.8rem' }}>
                                            {getTranslation("signup_success")}
                                        </Typography>
                                        <Typography style={{ fontSize: '1.2rem', lineHeight: '2.2' }}>
                                            {getTranslation("signup_success_registered")}
                                            <br />
                                            {getTranslation("signup_success_email_sent")}
                                            <br />
                                            {getTranslation("signup_success_or")}
                                            <br />
                                            {getTranslation("signup_success_download")}
                                            {
                                                <Link styled to="https://www.mentia.me/about-careplay" target="_blank" rel="noopener noreferrer">
                                                    https://www.mentia.me/about-careplay
                                                </Link>
                                            }
                                            {getTranslation("signup_success_download_2")}
                                            <br />
                                            {getTranslation("signup_success_or")}
                                            <br />
                                            {getTranslation("signup_success_mc_dw")}
                                        </Typography>
                                        {/* <Typography style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '5vh' }}>
                                            {getTranslation("signup_greetings_dashboard_careplay")}{' '} 
                                            {<Link styled to={CAREPLAY_CLIENT_URL} target="_blank" rel="noopener noreferrer">
                                                <img src={CarePlayLogo} height={40} alt="" style={{ margin: '5px' }} />
                                            </Link>}
                                        </Typography>
                                        <Typography style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            {getTranslation("signup_greetings_dashboard_devaworld")}{' '}
                                            {<Link styled to={DEVA_CLIENT_URL} target="_blank" rel="noopener noreferrer">
                                                <img src={DevaWorldLogo} height={40} alt="" style={{ margin: '5px' }} />
                                            </Link>}
                                        </Typography> */}
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </Col>
                </Row>
                )}
            </LocaleConsumer>
        );
    }

    static propTypes = {};
    static defaultProps = {};
}

export default withStyles(styles)(withRouter((JoinSuccessPage)));
